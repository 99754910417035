<template>
    <div class="fixed top-0 w-full z-50 bg-white">
        <div
            class="border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10 px-4"
        >
            <div
                class="flex justify-between items-center max-w-7xl mx-auto px-4 sm:px-6"
            >
                <a href="#">
                    <span class="sr-only">ZinseszinsApp</span>
                    <img
                        class="h-8 w-auto sm:h-10"
                        src="/img/icons/tecis-430x500.png"
                        alt=""
                    />
                </a>
                <select
                    v-model="calculation"
                    id="calculation"
                    class="focus:ring-tecis-himmelblau focus:border-tecis-himmelblau block shadow-sm sm:text-sm border-gray-300 rounded-md"
                    @change="this.emitter.emit('moveTo', 0)"
                >
                    <option value="0">Einmalzahlung</option>
                    <option value="1">Endkapital</option>
                    <option value="2">Sparrate</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppHeader',
    computed: {
        calculation: {
            get() {
                return this.$store.state.calculation;
            },
            set(value) {
                this.$store.commit('SET_CALCULATION', parseInt(value));
            }
        }
    }
};
</script>
