<template>
    <pie-chart :chartData="chartData" :options="options" class="h-full" />
</template>

<script>
import { Chart, registerables } from 'chart.js';
import { PieChart } from 'vue-chart-3';
Chart.register(...registerables);
import { mapGetters } from 'vuex';

export default {
    Name: 'FinalCapitalPie',
    components: {
        PieChart
    },
    computed: {
        ...mapGetters(['getFinalCapitalInterest']),
        R: {
            get() {
                return this.$store.state.R;
            }
        },
        A: {
            get() {
                return this.$store.state.A;
            }
        },
        S: {
            get() {
                return this.$store.state.S;
            }
        },
        chartData() {
            return {
                labels: ['Einzahlungen', 'Zinsen'],
                datasets: [
                    {
                        data: [
                            (this.R - this.A) * 12 * this.S,
                            this.getFinalCapitalInterest
                        ],
                        backgroundColor: ['#00325b', '#cbcdb9']
                    }
                ]
            };
        },
        options() {
            var that = this;
            return {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: 35
                },
                rotation: 120,
                hoverOffset: 50,
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                return (
                                    context.label +
                                    ': ' +
                                    that.convert(context.parsed) +
                                    ' €'
                                );
                            }
                        }
                    }
                }
            };
        }
    },
    methods: {
        convert(num) {
            return (Math.round(parseFloat(num) * 100) / 100).toLocaleString(
                'de-DE',
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                }
            );
        }
    }
};
</script>

<style></style>
