<template>
    <div
        class="fixed bottom-0 w-full px-4 py-3 bg-gray-50 text-right sm:px-6 z-50"
    >
        <div
            class="max-w-7xl mx-auto px-4 sm:px-6 flex justify-between items-center"
        >
            <button
                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-tecis-blau focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tecis-himmelblau"
                @click="prev"
            >
                Zurück
            </button>
            <span>{{ slideIndex }}/4</span>
            <button
                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-tecis-blau focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tecis-himmelblau"
                @click="next"
            >
                Weiter
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppFooter',
    data: () => ({
        slideIndex: 1
    }),
    methods: {
        next() {
            this.emitter.emit('next-slide');
        },
        prev() {
            this.emitter.emit('prev-slide');
        }
    },
    mounted() {
        this.emitter.on('index-slide', (index) => {
            this.slideIndex = index + 1;
        });
    }
};
</script>

<style></style>
