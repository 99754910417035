<template>
    <div class="relative bg-white" style="margin: 90px 0 62px 0">
        <app-header class="mb-6" />
        <div class="max-w-7xl mx-auto px-4 sm:px-6">
            <router-view />
        </div>
        <app-footer />
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
    name: 'App',
    components: {
        AppHeader,
        AppFooter
    }
};
</script>
