<template>
    <div class="home">
        <Flicking ref="flick" class="pie-panel" :options="{ circular: true }">
            <div class="panel w-full" :key="0">
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="px-4 py-5 bg-white sm:p-6">
                        <payments-form />
                    </div>
                </div>
            </div>
            <div class="panel w-full" :key="1">
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="px-4 py-5 bg-white sm:p-6">
                        <div v-if="calculation == 0">
                            <initial-capital />
                        </div>
                        <div v-if="calculation == 1">
                            <final-capital />
                        </div>
                        <div v-if="calculation == 2">
                            <savings-rate />
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel overflow-y-scroll w-full" :key="2">
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="px-4 py-5 bg-white sm:p-6 h-full">
                        <div v-if="calculation == 0">
                            <initial-capital-table />
                        </div>
                        <div v-if="calculation == 1">
                            <final-capital-table />
                        </div>
                        <div v-if="calculation == 2">
                            <savings-rate-table />
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel w-full" :key="3">
                <div class="mt-5 md:mt-0 md:col-span-2 h-full">
                    <div class="px-4 py-5 bg-white sm:p-6 h-full">
                        <div v-if="calculation == 0">
                            <initial-capital-pie />
                        </div>
                        <div v-if="calculation == 1">
                            <final-capital-pie />
                        </div>
                        <div v-if="calculation == 2">
                            <savings-rate-pie />
                        </div>
                    </div>
                </div>
            </div>
        </Flicking>
    </div>
</template>

<script>
import Flicking from '@egjs/vue3-flicking';
import PaymentsForm from '@/components/PaymentsForm';
import FinalCapital from '@/components/FinalCapital';
import SavingsRate from '@/components/SavingsRate';
import InitialCapital from '@/components/InitialCapital';
import FinalCapitalTable from '@/components/FinalCapitalTable';
import InitialCapitalTable from '@/components/InitialCapitalTable';
import SavingsRateTable from '@/components/SavingsRateTable';
import FinalCapitalPie from '@/components/FinalCapitalPie';
import InitialCapitalPie from '@/components/InitialCapitalPie';
import SavingsRatePie from '@/components/SavingsRatePie';

export default {
    components: {
        Flicking,
        PaymentsForm,
        FinalCapital,
        InitialCapital,
        SavingsRate,
        FinalCapitalTable,
        InitialCapitalTable,
        SavingsRateTable,
        FinalCapitalPie,
        InitialCapitalPie,
        SavingsRatePie
    },
    name: 'Home',
    computed: {
        calculation: {
            get() {
                return this.$store.state.calculation;
            }
        }
    },
    methods: {
        getProcent(value) {
            return (value / this.getProcessResultOld) * 100;
        }
    },
    mounted() {
        this.$refs.flick.on('changed', (flick) => {
            this.emitter.emit('index-slide', flick.index);
        });

        this.emitter.on('next-slide', () => {
            this.$refs.flick.next();
        });

        this.emitter.on('prev-slide', () => {
            this.$refs.flick.prev();
        });

        this.emitter.on('moveTo', (index) => {
            this.$refs.flick.moveTo(index);
        });
    }
};
</script>

<style scoped>
.pie-panel {
    height: calc(100vh - 152px);
}
</style>
