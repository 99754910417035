<template>
    <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6" v-if="calculation == 1">
            <label for="S" class="block text-sm font-medium text-gray-700"
                >Sparrate</label
            >
            <div class="mt-1 flex rounded-md shadow-sm">
                <input
                    type="number"
                    step="0.01"
                    v-model.number="S"
                    id="S"
                    class="focus:ring-tecis-himmelblau focus:border-tecis-himmelblau flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                    required=""
                /><span
                    class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm"
                >
                    €
                </span>
            </div>
        </div>

        <div class="col-span-6" v-if="calculation != 1">
            <label for="E" class="block text-sm font-medium text-gray-700"
                >Endkapital</label
            >
            <div class="mt-1 flex rounded-md shadow-sm">
                <input
                    type="number"
                    step="0.01"
                    v-model.number="E"
                    id="E"
                    class="focus:ring-tecis-himmelblau focus:border-tecis-himmelblau flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                    required=""
                /><span
                    class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm"
                >
                    €
                </span>
            </div>
        </div>

        <div class="col-span-6">
            <label for="V" class="block text-sm font-medium text-gray-700"
                >Verzinsung</label
            >
            <select
                v-model="V"
                id="V"
                class="mt-1 focus:ring-tecis-himmelblau focus:border-tecis-himmelblau block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
            >
                <option value="0.02">2%</option>
                <option value="0.03">3%</option>
                <option value="0.04">4%</option>
                <option value="0.05">5%</option>
                <option value="0.06">6%</option>
                <option value="0.07">7%</option>
                <option value="0.08">8%</option>
            </select>
        </div>

        <div class="col-span-6">
            <label for="R" class="block text-sm font-medium text-gray-700"
                >Renteneintrittsalter</label
            >
            <select
                v-model.number="R"
                id="R"
                class="mt-1 focus:ring-tecis-himmelblau focus:border-tecis-himmelblau block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
            >
                <option value="62">62</option>
                <option value="63">63</option>
                <option value="64">64</option>
                <option value="65">65</option>
                <option value="66">66</option>
                <option value="67">67</option>
                <option value="68">68</option>
                <option value="69">69</option>
                <option value="70">70</option>
                <option value="71">71</option>
                <option value="72">72</option>
            </select>
        </div>

        <div class="col-span-6">
            <label for="A" class="block text-sm font-medium text-gray-700"
                >Alter heute</label
            >
            <input
                type="number"
                v-model.number="A"
                id="A"
                class="mt-1 focus:ring-tecis-himmelblau focus:border-tecis-himmelblau block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
                min="1"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'PaymentsForm',
    computed: {
        calculation: {
            get() {
                return this.$store.state.calculation;
            }
        },
        S: {
            get() {
                return this.$store.state.S;
            },
            set(value) {
                this.$store.commit('SET_S', parseFloat(value));
            }
        },
        E: {
            get() {
                return this.$store.state.E;
            },
            set(value) {
                this.$store.commit('SET_E', parseFloat(value));
            }
        },
        V: {
            get() {
                return this.$store.state.V;
            },
            set(value) {
                this.$store.commit('SET_V', parseFloat(value));
            }
        },
        R: {
            get() {
                return this.$store.state.R;
            },
            set(value) {
                this.$store.commit('SET_R', parseInt(value));
            }
        },
        A: {
            get() {
                return this.$store.state.A;
            },
            set(value) {
                this.$store.commit('SET_A', parseInt(value));
            }
        }
    }
};
</script>

<style></style>
