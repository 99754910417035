<template>
    <table class="table-auto w-full">
        <thead></thead>
        <tbody>
            <tr
                v-for="item in getInitialCapitalCreditDevelopment"
                :key="item.year"
                class="pb-4 pt-2 border-dotted border-b-2"
            >
                <td class="text-tecis-blau font-bold text-lg">
                    {{ item.year }}. Jahr
                </td>
                <td class="text-tecis-blau">
                    <div>Einzahlung</div>
                    <div>Zinsgutschrift</div>
                    <div>neues Guthaben</div>
                </td>
                <td class="text-right">
                    <div class="text-gray-500">
                        {{ convert(item.deposits) }} €
                    </div>
                    <div class="text-gray-500">
                        {{ convert(item.interest_credits) }} €
                    </div>
                    <div>{{ convert(item.new_balance) }} €</div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    Name: 'InitialCapitalTable',
    computed: {
        ...mapGetters(['getInitialCapitalCreditDevelopment'])
    },
    methods: {
        convert(num) {
            return (Math.round(parseFloat(num) * 100) / 100).toLocaleString(
                'de-DE',
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                }
            );
        }
    }
};
</script>

<style></style>
