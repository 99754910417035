<template>
    <div>
        <div class="pb-4 border-dotted border-b-2">
            <div class="text-tecis-blau text-xl font-bold">
                Das erzielte Endkapital
            </div>
            bei einem Zinssatz von
            <span class="text-tecis-limette font-semibold">{{ V * 100 }}%</span>
            <div>{{ convert(getFinalCapital) }} €</div>
        </div>

        <div class="pb-4 pt-2 border-dotted border-b-2">
            <div class="text-tecis-blau text-xl font-bold">
                Einzahlungen gesamt
            </div>
            bei
            <span class="text-tecis-limette font-semibold">{{
                (R - A) * 12
            }}</span>
            monatliche Sparraten
            <div>{{ convert((R - A) * 12 * S) }} €</div>
        </div>

        <div class="pb-4 pt-2">
            <div class="text-tecis-blau text-xl font-bold">Zinsen gesamt</div>
            <div class="text-tecis-limette font-bold text-2xl">
                {{ convert(getFinalCapitalInterest) }} €
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    Name: 'FinalCapital',
    computed: {
        ...mapGetters(['getFinalCapital', 'getFinalCapitalInterest']),
        V: {
            get() {
                return this.$store.state.V;
            }
        },
        R: {
            get() {
                return this.$store.state.R;
            }
        },
        A: {
            get() {
                return this.$store.state.A;
            }
        },
        S: {
            get() {
                return this.$store.state.S;
            }
        }
    },
    methods: {
        convert(num) {
            return (Math.round(parseFloat(num) * 100) / 100).toLocaleString(
                'de-DE',
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                }
            );
        }
    }
};
</script>

<style></style>
