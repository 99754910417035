import { createStore } from 'vuex';

export default createStore({
    state: {
        calculation: 0,
        S: 100,
        E: 10000,
        V: 0.03,
        R: 67,
        A: 30
    },
    getters: {
        getSavingsRate: (state) => {
            // R = K / ((m+(q-1)*(m+1)/2)*(q^n-1)/(q-1))
            var K = state.E;
            var m = 12;
            var q = 1 + state.V;
            var n = state.R - state.A;

            var aa = m + ((q - 1) * (m + 1)) / 2;
            var bb = (Math.pow(q, n) - 1) / (q - 1);

            var result = K / (aa * bb);

            return result;
        },
        getSavingsRateCapital: (state, getters) => {
            return getters.getSavingsRateCreditDevelopment[
                getters.getSavingsRateCreditDevelopment.length - 1
            ].new_balance;
        },
        getSavingsRateCreditDevelopment: (state, getters) => {
            let roundes_s =
                Math.round(parseFloat(getters.getSavingsRate) * 100) / 100;
            let credit_development = [
                {
                    year: 1,
                    credit_to_beginning: 0,
                    deposits: 12 * roundes_s,
                    interest_credits:
                        roundes_s * (12 + (state.V / 12) * (13 * 6)) -
                        12 * roundes_s,
                    new_balance: roundes_s * (12 + (state.V / 12) * (13 * 6))
                }
            ];
            for (let i = 1; i < state.R - state.A; i++) {
                credit_development.push({
                    year: i + 1,
                    credit_to_beginning: credit_development[i - 1].new_balance,
                    deposits: 12 * roundes_s,
                    interest_credits:
                        roundes_s * (12 + (state.V / 12) * (13 * 6)) +
                        credit_development[i - 1].new_balance * (1 + state.V) -
                        credit_development[i - 1].new_balance -
                        12 * roundes_s,
                    new_balance:
                        roundes_s * (12 + (state.V / 12) * (13 * 6)) +
                        credit_development[i - 1].new_balance * (1 + state.V)
                });
            }
            return credit_development;
        },
        getSavingsRateInterest: (state, getters) => {
            let sum = 0;
            getters.getSavingsRateCreditDevelopment.forEach((element) => {
                sum += element.interest_credits;
            });
            return sum;
        },
        getInitialCapital: (state) => {
            return state.E / Math.pow(1 + state.V, state.R - state.A);
        },
        getInitialCapitalCreditDevelopment: (state, getters) => {
            let credit_development = [
                {
                    year: 1,
                    credit_to_beginning: getters.getInitialCapital,
                    deposits: 0,
                    interest_credits: getters.getInitialCapital * state.V,
                    new_balance: getters.getInitialCapital * (1 + state.V)
                }
            ];
            for (let i = 1; i < state.R - state.A; i++) {
                credit_development.push({
                    year: i + 1,
                    credit_to_beginning: credit_development[i - 1].new_balance,
                    deposits: 0,
                    interest_credits:
                        credit_development[i - 1].new_balance * state.V,
                    new_balance:
                        credit_development[i - 1].new_balance * (1 + state.V)
                });
            }
            return credit_development;
        },
        getInitialCapitalInterest: (state, getters) => {
            let sum = 0;
            getters.getInitialCapitalCreditDevelopment.forEach((element) => {
                sum += element.interest_credits;
            });
            return sum;
        },
        getFinalCapital: (state, getters) => {
            return getters.getFinalCapitalCreditDevelopment[
                getters.getFinalCapitalCreditDevelopment.length - 1
            ].new_balance;
        },
        getFinalCapitalCreditDevelopment: (state) => {
            let credit_development = [
                {
                    year: 1,
                    credit_to_beginning: 0,
                    deposits: 12 * state.S,
                    interest_credits: (state.V / 12) * (13 * 6) * 100,
                    new_balance: state.S * (12 + (state.V / 12) * (13 * 6))
                }
            ];
            for (let i = 1; i < state.R - state.A; i++) {
                credit_development.push({
                    year: i + 1,
                    credit_to_beginning: credit_development[i - 1].new_balance,
                    deposits: 12 * state.S,
                    interest_credits:
                        (state.V / 12) * (13 * 6) * 100 +
                        credit_development[i - 1].new_balance * state.V,
                    new_balance:
                        state.S * (12 + (state.V / 12) * (13 * 6)) +
                        credit_development[i - 1].new_balance * (1 + state.V)
                });
            }
            return credit_development;
        },
        getFinalCapitalInterest: (state, getters) => {
            let sum = 0;
            getters.getFinalCapitalCreditDevelopment.forEach((element) => {
                sum += element.interest_credits;
            });
            return sum;
        }
    },
    mutations: {
        SET_CALCULATION(state, calculation) {
            state.calculation = calculation;
        },
        SET_S(state, S) {
            state.S = S;
        },
        SET_E(state, E) {
            state.E = E;
        },
        SET_V(state, V) {
            state.V = V;
        },
        SET_R(state, R) {
            state.R = R;
        },
        SET_A(state, A) {
            state.A = A;
        }
    },
    actions: {
        SET_CALCULATION({ commit }, calculation) {
            commit('SET_CALCULATION', calculation);
        },
        SET_S({ commit }, S) {
            commit('SET_S', S);
        },
        SET_E({ commit }, E) {
            commit('SET_E', E);
        },
        SET_V({ commit }, V) {
            commit('SET_V', V);
        },
        SET_R({ commit }, R) {
            commit('SET_R', R);
        },
        SET_A({ commit }, A) {
            commit('SET_A', A);
        }
    },
    modules: {}
});
